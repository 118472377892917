var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"appNavbar"}},[_c('vue-element-loading',{attrs:{"active":_vm.appLoading,"is-full-screen":true,"background-color":"#FFFFFF","color":"#005f32","spinner":"bar-fade-scale"}}),_c('v-navigation-drawer',{attrs:{"fixed":"","temporary":"","color":"#fffcf5"},model:{value:(_vm.sideNav),callback:function ($$v) {_vm.sideNav=$$v},expression:"sideNav"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},_vm._l((_vm.appNavItems),function(item,i){return _c('v-flex',{key:i,attrs:{"xs12":"","text-center":"","pa-2":"","pl-0":"","text-none":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","px-0":"","pl-2":"","align-self-center":"","text-left":""}},[_c('v-icon',[_vm._v(_vm._s(item.icon))]),_c('a',{attrs:{"href":item.route}},[_c('span',{staticStyle:{"font-size":"15px","color":"black","letter-spacing":"3px","cursor":"pointer"},style:(_vm.$route.path == item.route
                    ? {
                        'font-family': 'TitBold',
                      }
                    : {
                        'font-family': 'TitLight',
                      })},[_vm._v(" "+_vm._s(item.name)+" ")])])],1)],1)],1)}),1)],1),_c('v-app-bar',{staticClass:"hidden-md-and-up",attrs:{"app":"","dark":"","color":"#005f32","dense":"","flat":"","height":"50px"}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('router-link',{attrs:{"to":"/"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs3":"","pa-0":"","sm3":"","md3":"","lg2":"","xl1":"","align-self-center":"","text-left":""}},[_c('router-link',{staticClass:"routerlink",attrs:{"to":(_vm.name = '/')}},[_c('v-img',{attrs:{"contain":"","height":"50px","src":require('./../assets/images/wtilogo.png')}})],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }